import Video from "../../Components/Video";
import Logo from "../../Assets/img/Logo";
import ContactForm from "../../Components/ContactForm";
import React from "react";
import {ContactTypes} from "./Contact.types";
export const ContactRender = ({intro, video}:ContactTypes) =>{
    return <div>
        {video &&
            <Video video={video} />
        }

        <div className="z-index-2 relative flex flex-column h-100vh">
            <div className="align-end grows flex">

            </div>
            <div id="logo-c" className="center pver-2 phor-2">
                <Logo/>
            </div>
            <ContactForm intro={intro}/>
        </div>
    </div>;
};