import {router} from './Routes';
import './Components/Assets/Fonts/Replica-Bold.woff'
import './Components/Assets/Fonts/Replica-Bold.woff2'
import './Components/Assets/Fonts/Replica-Light.woff'
import './Components/Assets/Fonts/Replica-Light.woff2'
import './Components/Assets/Fonts/Replica-Regular.woff'
import './Components/Assets/Fonts/Replica-Regular.woff2'
import './Components/Assets/Styles/index.css'
import {RouterProvider} from "react-router-dom";
const v = require('./Assets/img/y2mate.mp4');

function App() {
    return (
        <RouterProvider router={router} />
    );
}

export default App;
