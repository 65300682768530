import React from "react";
import Logo from "../../Assets/img/Logo";
import {PrivacyPolicyContainer, TableOfContents} from "./PrivacyPolicy.styles";
import {Link} from "react-router-dom";

export const PrivacyPolicyRender = (): JSX.Element => {
    return (
        <PrivacyPolicyContainer className="z-index-2 relative flex flex-column h-100vw">
            <div className="align-end grows flex"></div>
            <div id="logo-c" className="center pver-2 phor-2">
                <Link to="/" style={{display: 'block', textAlign: 'center'}}>
                    <Logo/>
                </Link>
            </div>
            <div className="align-end grows flex">
                <div className="w-100 phor-2 mhor-auto max-w-880px">
                    <h2 className="fs-2rem fw-300 center mb-2">
                        <b className="fw-bold">Privacy Policy</b><br/>
                    </h2>
                    <p className={"f-medium mb-2"} style={{color: 'hsl(246,  6%, 65%)'}}>Effective: August 15, 2024</p>
                    <p className="f-medium center mb-4">
                        Vision AR Privacy Policy explains how we collect and use your data and how you can control your
                        information. This policy covers Vision AR app, products, features and services.
                    </p>

                    <p className="leading-7" style={{marginBottom: '1.5rem'}}>
                        <strong><u>Privacy Policy Table of Contents</u></strong>
                    </p>
                    <TableOfContents className="list-none max-w-prose pl-5 mb-4 mt-0">
                        <li className="mb-3" ><a className="cursor-pointer underline"
                                                href="#managing-your-personal-data">Managing Your Personal Data</a></li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#information-we-collect">Information
                            We Collect</a></li>
                        <ul className="list-disc max-w-prose pl-5 mb-4 mt-0">
                            <li className="mb-3"><a className="cursor-pointer underline"
                                                    href="#information-you-provide">Information You Provide</a></li>
                            <li className="mb-3"><a className="cursor-pointer underline"
                                                    href="#information-we-generate">Information We Generate When You Use
                                Our Services</a></li>
                            <li className="mb-3"><a className="cursor-pointer underline" href="#enhance-features">Enhance
                                & Refine Features, Algorithms, and Machine Learning Models</a></li>
                        </ul>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#analytics">Analytics</a>
                        </li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#research">Research</a></li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#safety-and-security">Enhance
                            the Safety & Security of Our Services</a></li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#contacting-you">Contacting
                            You</a></li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#support">Support</a></li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#enforce-terms">Enforce Our
                            Terms & Policies</a></li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#legal-safety">Legal, Safety,
                            and Security Partners</a></li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#data-retention">How Long We
                            Keep Your Information</a></li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#audience">Our Audience</a>
                        </li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#updates">Updates to the
                            Privacy Policy</a></li>
                        <li className="mb-3"><a className="cursor-pointer underline" href="#contact">Contact Us</a></li>
                    </TableOfContents>

                    <h3 id="managing-your-personal-data" className="mb">Managing Your Personal Data</h3>
                    <p className="mb-2">
                        We want you to have control over your information, so we offer a variety of tools, including:
                    </p>
                    <TableOfContents className="mb-4">
                        <li><b>Access and update your information:</b> You can access and edit most of your basic
                            personal information right in our settings sections.
                        </li>
                        <li><b>Delete your information:</b> You can delete your account in our settings menu located in
                            your profile section of the app.
                        </li>
                        <li><b>Opt out of promotional messages:</b> You have the option to opt out or unsubscribe from
                            promotional emails and messages.
                        </li>
                    </TableOfContents>

                    <h3 id="information-we-collect" className="mb">Information We Collect</h3>
                    <p className="mb-2">
                        This section outlines the information we collect. When you use our services, like Vision AR, we
                        gather the information you provide, generate data as you interact with our services, and
                        sometimes receive information from third parties.
                    </p>

                    <h4 id="information-you-provide" className="mb">Information You Provide</h4>
                    <p className="mb-4">
                        Many of our Services require you to set up an account. To do this, we ask you to provide us with
                        account details like your name, username, email address, gender, and bio. You’ll also provide
                        profile details such as a photo of yourself.
                    </p>

                    <h4 id="information-we-generate" className="mb">Information We Generate When You Use Our
                        Services</h4>
                    <p className="mb-4">
                        Content information includes details based on the content of the image, video, or audio you
                        post. This includes device information, location information, information collected by cookies,
                        and log information.
                    </p>

                    <h4 id="enhance-features" className="mb">Enhance & Refine Features, Algorithms, and Machine Learning
                        Models</h4>
                    <p className="mb-4">
                        We continually develop and refine the algorithms and machine learning models that power our
                        features, including generative AI. Your information helps us decide what improvements to make,
                        always prioritizing privacy.
                    </p>

                    <h3 id="analytics" className="mb">Analytics</h3>
                    <p className="mb-4">
                        To understand trends and user demand, we analyze data to identify patterns in how people
                        interact with the services. This enables us to make broader improvements to Vision AR.
                    </p>

                    <h3 id="research" className="mb">Research</h3>
                    <p className="mb-4">
                        We conduct research to better understand consumer interests, trends, and how you and others use
                        our Services. Findings may be incorporated into Vision AR features.
                    </p>

                    <h3 id="safety-and-security" className="mb">Enhance the Safety & Security of Our Services</h3>
                    <p className="mb-4">
                        We use your information to improve safety and security, verify identities, and prevent fraud. We
                        offer two-factor authentication and can notify you if we detect suspicious activity.
                    </p>

                    <h3 id="contacting-you" className="mb">Contacting You</h3>
                    <p className="mb-4">
                        We may contact you to promote new features or provide important information. This could include
                        sending communications via email, SMS, or other messaging platforms.
                    </p>

                    <h3 id="support" className="mb">Support</h3>
                    <p className="mb-4">
                        When you ask for help, we use the information we’ve collected to respond quickly and resolve
                        issues with our Services.
                    </p>

                    <h3 id="enforce-terms" className="mb">Enforce Our Terms & Policies</h3>
                    <p className="mb-4">
                        We use the data we collect to uphold our Terms, investigate violations, and cooperate with law
                        enforcement as necessary.
                    </p>

                    <h3 id="legal-safety" className="mb">Legal, Safety, and Security Partners</h3>
                    <p className="mb-4">
                        We share information about your activity for legal, safety, and security reasons, and if
                        necessary, for mergers or acquisitions.
                    </p>

                    <h3 id="data-retention" className="mb">How Long We Keep Your Information</h3>
                    <p className="mb-4">
                        We retain your information as long as necessary to operate our Services or to comply with legal
                        obligations.
                    </p>

                    <h3 id="audience" className="mb">Our Audience</h3>
                    <p className="mb-4">
                        Our Services are directed to individuals 13 years and older. We don’t knowingly collect data
                        from children under 13.
                    </p>

                    <h3 id="updates" className="mb">Updates to the Privacy Policy</h3>
                    <p className="mb-4">
                        We may update our Privacy Policy periodically. When we do, we’ll inform you through our website
                        or in-app notifications.
                    </p>

                    <h3 id="contact" className="mb">Contact Us</h3>
                    <p className="mb-4">
                        Have any questions about the information here? You can contact us at <a
                        href="mailto:support@thevision.ar" target="_blank" style={{color: "teal"}}>support@thevision.ar</a>.
                    </p>
                </div>
            </div>
        </PrivacyPolicyContainer>
    );
};