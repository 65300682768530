import React from 'react'

interface VideoProps {
	video: string
	cover?: string
}

export default function Video({cover, video}: VideoProps):JSX.Element {
	return (
		<video className="w-100 ratio-16-9 op-40" playsInline={true} autoPlay={true} muted={true} loop={true} poster={cover || ''} controlsList="nodownload">
			<source src={video} type="video/mp4" />
		</video>
	);
}