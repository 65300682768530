import {useState} from "react";
import {ContactRender} from "./Contact.render";


export const ContactLoad = () => {
    const [intro, setIntro] = useState('');
    const [video, setVideo] = useState('https://vision-ar-web.s3.us-east-2.amazonaws.com/y2mate.mp4');
  return  <ContactRender
      intro={intro}
      video={video}
  />
};