import React from "react";
import {createBrowserRouter, RouterProviderProps} from "react-router-dom";
import {Contact} from "../Pages";
import { PrivacyPolicyRoutes } from "./PrivacyPolicy.routes";
import {ContactRoutes} from "./Contact.routes";
import {TermsOfServiceRoutes} from "./TermsOfService.routes";

export const router: RouterProviderProps['router'] = createBrowserRouter([

    {
        children:[
            ...ContactRoutes,
            ...PrivacyPolicyRoutes,
            ...TermsOfServiceRoutes,
            {
                path: "*",
                element: React.createElement(Contact)
            }
        ]
    }
]);