import React from 'react';

interface LogoProps {
	width?: number
	height?: number
}

export default function Logo({width=168, height=230}:LogoProps) {
	return (
		<svg width={width} height={height} viewBox="0 0 168 230" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M22.4664 145.96H30.9773C31.2519 145.96 31.4807 146.066 31.6637 146.28C31.8772 146.463 31.984 146.646 31.984 146.829C31.984 147.012 31.9687 147.165 31.9382 147.287L23.3358 176.48C23.0613 177.487 22.3749 177.99 21.2767 177.99H10.844C9.74581 177.99 9.05945 177.487 8.7849 176.48L0.182476 147.287C0.151971 147.165 0.136719 147.012 0.136719 146.829C0.136719 146.646 0.228234 146.463 0.411264 146.28C0.6248 146.066 0.86884 145.96 1.14339 145.96H9.65429C10.7525 145.96 11.4388 146.463 11.7134 147.47L16.0604 164.491L20.4073 147.47C20.6819 146.463 21.3682 145.96 22.4664 145.96Z" fill="white"/>
			<path d="M46.1957 177.99H36.8611C36.5256 177.99 36.2358 177.868 35.9917 177.624C35.7477 177.38 35.6257 177.09 35.6257 176.754V147.195C35.6257 146.859 35.7477 146.57 35.9917 146.326C36.2358 146.082 36.5256 145.96 36.8611 145.96H46.1957C46.5312 145.96 46.821 146.082 47.065 146.326C47.3091 146.57 47.4311 146.859 47.4311 147.195V176.754C47.4311 177.09 47.3091 177.38 47.065 177.624C46.821 177.868 46.5312 177.99 46.1957 177.99Z" fill="white"/>
			<path d="M52.542 156.026C52.542 152.915 53.8232 150.383 56.3857 148.43C58.9786 146.478 62.3646 145.502 66.5438 145.502C70.7535 145.502 74.1853 146.524 76.8393 148.568C79.5237 150.612 80.866 152.747 80.866 154.974C80.866 155.248 80.7592 155.492 80.5457 155.706C80.3626 155.889 80.1491 155.98 79.905 155.98H70.7535C70.0519 155.98 69.4113 155.721 68.8317 155.203C68.2521 154.684 67.4742 154.425 66.4981 154.425C65.0338 154.425 64.3017 154.852 64.3017 155.706C64.3017 156.164 64.6525 156.545 65.3541 156.85C66.0863 157.155 67.4285 157.429 69.3808 157.674C73.926 158.253 77.1596 159.321 79.0814 160.877C81.0337 162.402 82.0099 164.735 82.0099 167.877C82.0099 170.989 80.5762 173.536 77.7087 175.519C74.8717 177.471 71.1501 178.447 66.5438 178.447C61.9376 178.447 58.3227 177.502 55.6993 175.611C53.0759 173.719 51.7641 171.416 51.7641 168.701C51.7641 168.427 51.8557 168.198 52.0387 168.015C52.2522 167.801 52.4963 167.694 52.7708 167.694H61.4647C62.1053 167.694 62.807 167.999 63.5696 168.61C64.3627 169.22 65.4456 169.525 66.8184 169.525C69.1063 169.525 70.2502 169.098 70.2502 168.244C70.2502 167.694 69.8384 167.283 69.0147 167.008C68.1911 166.703 66.6353 166.398 64.3475 166.093C56.4772 165.056 52.542 161.7 52.542 156.026Z" fill="white"/>
			<path d="M96.9133 177.99H87.5787C87.2432 177.99 86.9534 177.868 86.7093 177.624C86.4653 177.38 86.3433 177.09 86.3433 176.754V147.195C86.3433 146.859 86.4653 146.57 86.7093 146.326C86.9534 146.082 87.2432 145.96 87.5787 145.96H96.9133C97.2488 145.96 97.5386 146.082 97.7827 146.326C98.0267 146.57 98.1487 146.859 98.1487 147.195V176.754C98.1487 177.09 98.0267 177.38 97.7827 177.624C97.5386 177.868 97.2488 177.99 96.9133 177.99Z" fill="white"/>
			<path d="M107.332 148.888C110.139 146.631 113.845 145.502 118.451 145.502C123.057 145.502 126.749 146.646 129.524 148.934C132.331 151.191 133.734 154.379 133.734 158.497V165.635C133.734 169.815 132.346 173.002 129.57 175.199C126.825 177.365 123.118 178.447 118.451 178.447C113.784 178.447 110.062 177.365 107.286 175.199C104.541 173.002 103.168 169.815 103.168 165.635V158.497C103.168 154.348 104.556 151.145 107.332 148.888ZM115.98 168.61C116.59 169.22 117.414 169.525 118.451 169.525C119.488 169.525 120.312 169.22 120.922 168.61C121.532 167.999 121.837 167.069 121.837 165.818V158.131C121.837 156.911 121.532 155.996 120.922 155.386C120.312 154.745 119.488 154.425 118.451 154.425C117.414 154.425 116.59 154.745 115.98 155.386C115.37 155.996 115.065 156.911 115.065 158.131V165.818C115.065 167.069 115.37 167.999 115.98 168.61Z" fill="white"/>
			<path d="M158.069 145.96H166.351C166.686 145.96 166.976 146.082 167.22 146.326C167.464 146.57 167.586 146.859 167.586 147.195V176.754C167.586 177.09 167.464 177.38 167.22 177.624C166.976 177.868 166.686 177.99 166.351 177.99H159.121C158.328 177.99 157.703 177.639 157.245 176.937L149.512 165.269V176.754C149.512 177.09 149.39 177.38 149.146 177.624C148.902 177.868 148.612 177.99 148.277 177.99H139.994C139.659 177.99 139.369 177.868 139.125 177.624C138.881 177.38 138.759 177.09 138.759 176.754V147.195C138.759 146.859 138.881 146.57 139.125 146.326C139.369 146.082 139.659 145.96 139.994 145.96H147.224C148.078 145.96 148.704 146.31 149.1 147.012L156.833 159.687V147.195C156.833 146.859 156.955 146.57 157.199 146.326C157.443 146.082 157.733 145.96 158.069 145.96Z" fill="white"/>
			<path d="M56.2196 188H69.2396C70.6796 188 71.5796 188.66 71.9396 189.98L85.3196 228.26C85.3596 228.38 85.3796 228.58 85.3796 228.86C85.3796 229.1 85.2396 229.36 84.9596 229.64C84.7196 229.88 84.4196 230 84.0596 230H72.9596C71.7596 230 70.9796 229.5 70.6196 228.5L69.1796 224.54H56.2796L54.8396 228.5C54.4796 229.5 53.6996 230 52.4996 230H41.3996C41.0396 230 40.7196 229.88 40.4396 229.64C40.1996 229.36 40.0796 229.1 40.0796 228.86C40.0796 228.58 40.0996 228.38 40.1396 228.26L53.5196 189.98C53.8796 188.66 54.7796 188 56.2196 188ZM59.3396 212.84H66.1196L62.6996 201.68L59.3396 212.84Z" fill="white"/>
			<path d="M128.829 228.02C128.949 228.26 129.009 228.52 129.009 228.8C129.009 229.08 128.869 229.36 128.589 229.64C128.349 229.88 128.049 230 127.689 230H115.389C114.149 230 113.289 229.46 112.809 228.38L107.649 216.92H105.189V228.38C105.189 228.82 105.029 229.2 104.709 229.52C104.389 229.84 104.009 230 103.569 230H91.809C91.369 230 90.989 229.84 90.669 229.52C90.349 229.2 90.189 228.82 90.189 228.38V189.62C90.189 189.18 90.349 188.8 90.669 188.48C90.989 188.16 91.369 188 91.809 188H109.569C115.329 188 119.829 189.3 123.069 191.9C126.309 194.46 127.929 198.16 127.929 203C127.929 207.8 125.769 211.58 121.449 214.34L128.829 228.02ZM105.189 199.16V205.52H109.569C110.449 205.52 111.129 205.24 111.609 204.68C112.089 204.08 112.329 203.34 112.329 202.46C112.329 201.58 112.089 200.82 111.609 200.18C111.169 199.5 110.489 199.16 109.569 199.16H105.189Z" fill="white"/>
			<mask id="mask0_211_400" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="12" y="0" width="116" height="116">
				<path d="M12 79.9331L91.6907 0.242421L127.582 36.1336L47.8912 115.824L12 79.9331Z" fill="#D9D9D9"/>
			</mask>
			<g mask="url(#mask0_211_400)">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M49.5192 78.0011L106.372 93.2348C106.542 93.2803 106.641 93.2837 106.684 93.2832C106.73 93.2827 106.767 93.2768 106.806 93.2656C106.894 93.2404 107.047 93.1687 107.203 93.0121C107.36 92.8555 107.431 92.7032 107.457 92.6154C107.468 92.5762 107.474 92.5386 107.474 92.4927C107.475 92.4503 107.471 92.3511 107.426 92.1811L92.1921 35.3282C92.1465 35.1582 92.0999 35.0706 92.0783 35.0342C92.0549 34.9947 92.031 34.9651 92.0016 34.9368C91.9359 34.8733 91.7977 34.7772 91.5838 34.7199C91.3699 34.6626 91.2021 34.6767 91.1134 34.6988C91.0739 34.7086 91.0383 34.7223 90.9984 34.7448C90.9614 34.7656 90.8772 34.8181 90.7528 34.9426L49.1336 76.5618C49.0091 76.6862 48.9566 76.7704 48.9358 76.8074C48.9133 76.8473 48.8996 76.8829 48.8898 76.9224C48.8677 77.0111 48.8536 77.1788 48.9109 77.3928C48.9682 77.6067 49.0643 77.7449 49.1278 77.8107C49.1561 77.84 49.1857 77.8639 49.2252 77.8873C49.2617 77.9089 49.3492 77.9555 49.5192 78.0011ZM101.919 109.855C115.352 113.454 127.645 101.161 124.046 87.7279L108.812 30.875C105.212 17.4414 88.4203 12.942 78.5863 22.776L36.967 64.3953C27.133 74.2293 31.6324 91.0213 45.066 94.6209L101.919 109.855Z" fill="white"/>
			</g>
			<mask id="mask1_211_400" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="37" y="42" width="121" height="121">
				<path d="M37.5498 126.166L121.499 42.2169L157.39 78.1081L73.441 162.057L37.5498 126.166Z" fill="#D9D9D9"/>
			</mask>
			<g mask="url(#mask1_211_400)">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M39.1779 88.3426L96.0308 103.576C96.2008 103.622 96.3 103.625 96.3424 103.625C96.3882 103.624 96.4259 103.618 96.465 103.607C96.5529 103.582 96.7052 103.51 96.8618 103.354C97.0184 103.197 97.0901 103.045 97.1153 102.957C97.1265 102.918 97.1324 102.88 97.1329 102.834C97.1334 102.792 97.13 102.693 97.0845 102.523L81.8508 45.6697C81.8052 45.4997 81.7586 45.4122 81.737 45.3757C81.7136 45.3362 81.6896 45.3066 81.6603 45.2783C81.5946 45.2148 81.4564 45.1187 81.2425 45.0614C81.0285 45.0041 80.8608 45.0182 80.7721 45.0403C80.7326 45.0501 80.697 45.0638 80.6571 45.0863C80.6201 45.1071 80.5359 45.1596 80.4115 45.2841L38.7922 86.9033C38.6678 87.0277 38.6153 87.1119 38.5945 87.1489C38.572 87.1888 38.5583 87.2244 38.5485 87.2639C38.5264 87.3526 38.5123 87.5204 38.5696 87.7343C38.6269 87.9482 38.723 88.0864 38.7865 88.1522C38.8148 88.1815 38.8444 88.2054 38.8839 88.2288C38.9203 88.2504 39.0079 88.2971 39.1779 88.3426ZM91.5776 120.196C105.011 123.796 117.304 111.503 113.704 98.0694L98.4705 41.2165C94.871 27.7829 78.079 23.2835 68.245 33.1175L26.6257 74.7368C16.7916 84.5708 21.2911 101.363 34.7247 104.962L91.5776 120.196Z" fill="white"/>
			</g>
		</svg>

	)
}