import React, {useState} from 'react'
import Logo from './Assets/Logo';
import {saveContact} from '../Actions/strapi';
import {Link} from "react-router-dom";

interface ContactProps{
	intro?:string
}

export default function ContactForm({intro}:ContactProps):JSX.Element {

	const [submitted, setSubmitted] = useState(false);
	const [data, setData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		company: '',
		message: ''
	});
	const [error, setError] = useState("");

	const submit = async () => {
		console.log(data);
		setError("");
		if(!data.firstName){
			setError("Please add your first name");
			return
		}
		if(!data.lastName){
			setError("Please add your last name");
			return
		}
		if(!data.email){
			setError("Please add a valid email");
			return
		}
		if(!data.message){
			setError("Please add a support message");
			return
		}
		// if(!data.company){
		// 	setError("Please add company name");
		// 	return
		// }
		//const s = await saveContact(data);
		//console.log(s);
		setSubmitted(true);
	}

	const changeInput = (e:any) => {
		setData({...data, [e.target.name]:e.target.value});
	}


	return (
		<div className="">
			<div className="w-100 phor-2 mhor-auto max-w-880px">
				<h2 className="fs-2rem fw-300 center mb-2">
					<b className="fw-bold">About Vision AR</b><br/>
					{/*Become an Opus insider today*/}
				</h2>
				<p className="f-medium center mb-4">
					Welcome to Vision AR, where we unlock the extraordinary within the ordinary. We are pioneers in the
					world of Augmented Reality, offering a groundbreaking app and marketplace for artists. Immerse
					yourself in a realm where reality meets imagination, gaining access to exclusive AR experiences.
				</p>

				<div className="mhor-auto max-w-600px center">
					<h2 className="fs-2rem mt-2 mb-2 center">Support / FAQ</h2>
					<h3 className="mb">
						What is the Vision AR App?
					</h3>
					<p className="mb">
						Vision AR specializes in augmented reality applications that seamlessly blend physical
						merchandise with immersive experiences. Our platform serves as a marketplace for artists, where
						fans can explore captivating AR-enhanced collections simply by scanning specially marked
						artworks.
					</p>
					<p className="mb-2">
						Experience AR content effortlessly through integrated camera features, capturing and sharing
						memorable moments with our AR enabled digital artwork.
					</p>

					<h3 className="mb">
						How do I find the artwork I want to scan.
					</h3>
					<p className="mb-2">
						Yes you must select the artwork you want to display via the app’s art feed. All of our
						AR-enabled artwork is previously scanned using computer vision technology therefore it will only
						work on AR trained graphics. If you don't own the artwork you can download from the detail
						screen on the "download" link.
					</p>


					<h3 className="mb">
						What type of iPhone do I need to activate the AR experience?
					</h3>
					<p className="mb-2">
						You must have an iPhone 8 version or higher in order to activate the AR experience.
					</p>
					<h3 className="mb">
						How do I know when a new collection is AR enabled?
					</h3>
					<p className="mb-2">
						We will announce via this website when a new AR enabled collection has launched.
					</p>

					<h3 className="mb">Who can I contact if my questions are not answered here?</h3>
					<p className="mb-2">
						You can contact us at <a href="mailto:support@thevision.ar" target="_blank"
												 style={{color: "teal"}}>support@thevision.ar</a> or fill the form
						below:
					</p>
				</div>


				<h2 className="fs-2rem mt-4 center">Contact Us</h2>
			</div>
			{submitted ?
				<div className="mhor-auto max-w-880px w-100">
					{/*<div className="center">
						<Logo width={193} height={23} />
					</div>*/}

					<h2 className="fs-2rem fw-300 center fs-5rem">
						Thank You
					</h2>
				</div>

				:
				<div className="w-100 pver-5">
					{/*<p className="max-w-1000px mhor-auto fw-300 center pver-3 fs-2rem lh-140 phor-2">*/}
					{/*	{intro}*/}
					{/*</p>*/}

					<form className="w-100 phor-2 mhor-auto max-w-880px">
						<div className="grid2 col-gap-2">
							<input type="text" name="firstName" placeholder="First Name *" onChange={changeInput}/>
							<input type="text" name="lastName" placeholder="Last Name *" onChange={changeInput}/>
						</div>
						<input type="text" name="email" placeholder="Email *" onChange={changeInput}/>
						<textarea name="message" onChange={changeInput} placeholder="Message"/>
						<p className="center">
							<button type="button" onClick={submit}>Submit</button>
						</p>
						{error &&
							<p className="center f-red mt-2 pb-4">
								{error}
							</p>
						}
					</form>
				</div>
			}
			<h5 className={"flex justify-center align-center center"} style={{paddingBottom: '2rem', gap:'1rem'}}>
				<Link to="/privacy-policy" style={{color: 'inherit', textDecoration: 'none'}}>
					Privacy Policy
				</Link>
				<Link to="/terms-of-service" style={{color: 'inherit', textDecoration: 'none'}}>
					Terms of Service
				</Link>
			</h5>
		</div>
	)
}