import styled from "styled-components";

export const TableOfContents = styled.ul`
    max-width: 65ch;
    margin-left: 1.25rem;
    color: white;
    
    a {
        color: white;
        text-decoration: underline;
        cursor: pointer;

        &:hover, &:focus {
            color: white;
        }
    }

    li {
        margin-bottom: 0.75rem;
    }

    ul {
        list-style-type: disc;
        padding-left: 1.25rem;
    }
`;

export const PrivacyPolicyContainer =styled.div`
    background-color: hsl(250, 24%, 9%);
`;