import {TableOfContents, TermsOfServiceContainer} from "./TermsOfService.styles";
import {Link} from "react-router-dom";
import Logo from "../../Assets/img/Logo";
import React from "react";

export const TermsOfServiceRender = (): JSX.Element => {
  return (
      <TermsOfServiceContainer className="z-index-2 relative flex flex-column h-100vw">
        <div className="align-end grows flex"></div>
        <div id="logo-c" className="center pver-2 phor-2">
          <Link to="/" style={{ display: 'block', textAlign: 'center' }}>
            <Logo />
          </Link>
        </div>
        <div className="align-end grows flex">
          <div className="w-100 phor-2 mhor-auto max-w-880px">
            <h2 className="fs-2rem fw-300 center mb-2">
              <b className="fw-bold">Terms of Service</b><br />
            </h2>
            <p className={"f-medium mb-2"} style={{ color: 'hsl(246,  6%, 65%)' }}>Effective: August 17, 2024</p>
            <p className="f-medium center mb-4">
              Welcome to Vision AR! We're excited to have you on board as we revolutionize the way people experience augmented reality. Whether you're here to explore, connect, or share your unique AR creations, Vision AR is the place for you. Before you get started, please take a moment to read through our Terms of Service, which govern your use of our platform.
            </p>
            <p className="f-medium center mb-4">
              If you are using the Services on behalf of a business or some other entity, you represent that you are authorized to bind that business or entity to these Terms and you agree to these Terms.
            </p>
            <p className="f-medium center mb-4">
              These Terms apply if you live in the United States or if your principal place of business is in the United States. If you live outside of the United States or if your principal place of business is outside of the United States, Vision AR LLC.
            </p>
            <p className="f-medium center mb-4" style={{ fontWeight: 'bold'}}>
              ARBITRATION NOTICE: THESE TERMS CONTAIN AN ARBITRATION CLAUSE A LITTLE LATER ON. YOU AND VISION AR AGREE THAT, EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT ARBITRATION CLAUSE, DISPUTES BETWEEN US WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU AND VISION AR WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. YOU HAVE THE RIGHT TO OPT OUT OF ARBITRATION AS EXPLAINED IN THAT ARBITRATION CLAUSE.
            </p>

            <p className="leading-7" style={{ marginBottom: '1.5rem' }}>
              <strong><u>Terms of Service Table of Contents</u></strong>
            </p>
            <TableOfContents className="list-none max-w-prose pl-5 mb-4 mt-0">
              <li className="mb-3"><a className="cursor-pointer underline" href="#who-can-use">Who Can Use the Services</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#rights-you-grant-us">Rights You Grant Us</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#privacy">Privacy</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#personalized-recommendations">Personalized Recommendations</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#content-moderation">Content Moderation</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#respecting-services">Respecting the Services and Vision AR Rights</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#respecting-others-rights">Respecting Others' Rights</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#safety">Safety</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#your-account">Your Account</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#data-charges">Data Charges and Mobile Phones</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#modifying-services">Modifying the Services and These Terms</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#termination">Termination and Suspension</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#indemnity">Indemnity</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#disclaimers">Disclaimers</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#limitation-liability">Limitation of Liability</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#choice-of-law">Choice of Law</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#severability">Severability</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#california-residents">California Residents</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#final-terms">Final Terms</a></li>
              <li className="mb-3"><a className="cursor-pointer underline" href="#contact">Contact Us</a></li>
            </TableOfContents>

            <h3 id="who-can-use" className="mb">Who Can Use the Services</h3>
            <p className="mb-4">
              Our Services are not intended for children under 13, and you must be at least 13 years old to create an account and use them. If we become aware that you are under 13, we will stop providing the Services to you and delete your account and data. Some Services may have additional terms requiring you to be older, so please review all terms carefully.
            </p>
            <p className="mb-2">
              By using the Services, you confirm that:
            </p>
            <ul className="list-disc pl-5 mb-4" style={{paddingLeft:'1.2rem'}}>
              <li>You are able to enter into a binding contract with Vision AR;</li>
              <li>You are not prohibited from using the Services under U.S. or other applicable laws, including not being on the U.S. Treasury Department’s list of Specially Designated Nationals or facing similar restrictions;</li>
              <li>You are not a convicted sex offender; and</li>
              <li>You will adhere to these Terms, including all related guidelines and policies, as well as all applicable laws and regulations.</li>
            </ul>
            <p className="mb-4">
              If you are using the Services on behalf of a business or other entity, you confirm that you have the authority to bind that business or entity to these Terms and agree to them on its behalf (in which case "you" and "your" will refer to both you and the business or entity). If you are representing a U.S. Government entity, you agree to the Amendment to Vision AR Inc. Terms of Service for U.S. Government Users.
            </p>

            <h3 id="rights-you-grant-us" className="mb">Rights You Grant Us</h3>
            <p className="mb-4">
              Many of our Services let you create, upload, post, send, receive, and store content. When you do that, you retain whatever ownership rights in that content you had to begin with.
            </p>
            <p className="mb-4">
              For all content you submit to the Services (including Public Content), you grant Vision AR and our affiliates a worldwide, royalty-free, sublicensable, and transferable license to host, store, cache, use, display, reproduce, modify, adapt, edit, publish, analyze, transmit, and distribute that content. This license is for the purpose of operating, developing, providing, promoting, and improving the Services and researching and developing new ones. This license includes a right for us to make your content available to, and pass these rights along to, service providers with whom we have contractual relationships related to the provision of the Services, solely for the purpose of providing such Services.
            </p>
            <p className="mb-4">
              Because Public Content is inherently public, you grant Vision AR, our affiliates, other users of the Services, and our business partners an unrestricted, worldwide, royalty-free, irrevocable, and perpetual right and license to create derivative works from, promote, exhibit, broadcast, syndicate, reproduce, distribute, synchronize, overlay graphics and auditory effects on, publicly perform, and publicly display all or any portion of your Public Content in any form and in any and all media or distribution methods, now known or later developed, for commercial and non-commercial purposes. This license applies to the separate video, image, sound recording, or musical compositions contained in your Public Content, as well as the name, image, likeness, and voice of anyone featured in Public Content that you create, upload, post, send, or your feed. This means, among other things, that you will not be entitled to any compensation if your content, videos, photos, sound recordings, musical compositions, name, image, likeness, or voice are used by us, our affiliates, users of the Services, or our business partners.
            </p>
            <p className="mb-4">
              While we’re not required to do so, we may access, review, screen, and delete your content at any time and for any reason, including to provide and develop the Services or if we think your content violates these Terms or any applicable laws. You alone, though, remain responsible for the content you create, upload, post, send, or store through the Services.
            </p>
            <p className="mb-4">
              We always love to hear from our users. But if you provide feedback or suggestions, just know that we can use them without compensating you, and without any restriction or obligation to you. You agree that we will own all rights in anything we develop based on such feedback or suggestions.
            </p>

            <h3 id="privacy" className="mb">Privacy</h3>
            <p className="mb-4">
              Your privacy matters to us. You can learn how your information is handled when you use our Services by
              reading our <Link to="/privacy-policy" style={{color: '#fff', fontWeight:'600'}} className="underline">Privacy Policy</Link>.
            </p>

            <h3 id="personalized-recommendations" className="mb">Personalized Recommendations</h3>
            <p className="mb-4">
              Our Services provide a personalized experience to make them more relevant and engaging for you. We will recommend content, advertising, and other information to you based on what we know and infer about your and others’ interests from use of our Services. It is necessary for us to handle your personal information for this purpose, as we explain in our Privacy Policy. Personalization is also a condition of our contract with you for us to be able to do so, unless you opt to receive less personalization in the Services.
            </p>

            <h3 id="content-moderation" className="mb">Content Moderation</h3>
            <p className="mb-4">
              Much of the content on our Services is produced by users, publishers, and other third parties. Whether that content is posted publicly or privately, the content is the sole responsibility of the user or entity that submitted it. Although Vision AR reserves the right to review, moderate, or remove all content that appears on the Services, we do not review all of it. So we cannot — and do not — guarantee that all content will meet our standards.
            </p>
            <p className="mb-4">
              Users can report content produced by others or others’ accounts for violation of our Terms, other guidelines and policies. More information about how to report content and accounts is available in our Privacy Policy.
            </p>
            <p className="mb-2">
              We hope you’ll understand any decisions we make about content or user accounts, but if you have any complaints or concerns, you can email us at <a href="mailto:support@thevision.ar" target="_blank" style={{ color: "teal" }}>support@thevision.ar</a>.
              Upon receiving a complaint, we will:
            </p>
            <ul className="list-disc pl-5 mb-4" style={{paddingLeft:'1.2rem'}}>
            <li>Ensure the complaint is reviewed in a timely, non-discriminatory, diligent, and non-arbitrary manner;</li>
              <li>Reverse our decision if we determine our initial assessment was incorrect; and</li>
              <li>Inform you of our decision and of any possibilities for redress promptly.</li>
            </ul>

            <h3 id="respecting-services" className="mb">Respecting the Services and Vision AR Rights</h3>
            <p className="mb-2">
              As between you and us, Vision AR is the owner of the Services, which includes all related brands, works of authorship, and technology. You agree not to:
            </p>
            <ul className="list-disc pl-5 mb-4" style={{paddingLeft:'1.2rem'}}>
              <li>Use branding, logos, icons, user interface elements, product or brand look and feel, designs, photographs, videos, or any other materials Vision AR makes available via the Services, except as explicitly allowed by these Terms;</li>
              <li>Violate or infringe Vision AR, our affiliates’, or any other third party’s copyrights, trademarks, or other intellectual property rights, including by using the Services to submit, display, post, create, or generate any infringing content;</li>
              <li>Copy, modify, archive, download, upload, disclose, distribute, sell, lease, syndicate, broadcast, perform, display, make available, make derivatives of, or otherwise use the Services or the content on the Services, other than temporary files that are automatically cached by your web browser for display purposes, as otherwise expressly permitted in these Terms, as otherwise expressly permitted by us in writing, or as enabled by the Service’s intended functionality;</li>
              <li>Create another account if we have already disabled your account, attempt to access the Services through unauthorized third-party applications, solicit login credentials from other users, or buy, sell, rent, or lease access to your account, a username, Vision AR, or a friend link;</li>
              <li>Reverse engineer, duplicate, decompile, disassemble, or decode the Services (including any underlying idea or algorithm), or otherwise extract the source code of the software of the Service;</li>
              <li>Use any robot, spider, crawler, scraper, or other automated means or interface to access the Services or extract other users’ information;</li>
              <li>Use or develop any third-party applications that interact with the Services or other users’ content or information without our written consent;</li>
              <li>Use the Services in a way that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Services, or that could damage, disable, overburden, or impair the functioning of the Services;</li>
              <li>Upload viruses or other malicious code or otherwise compromise, bypass, or circumvent the security of the Services;</li>
              <li>Attempt to circumvent any content-filtering techniques we employ, or attempt to access areas or features of the Services that you are not authorized to access;</li>
              <li>Probe, scan, or test the vulnerability of our Services or any system or network;</li>
              <li>Violate any applicable law or regulation in connection with your access to or use of the Services.</li>
            </ul>
            <p className="mb-4" style={{marginTop:'1rem',fontStyle: 'italic'}}>
              In summary: We own and manage all the content, features, and functionalities of the Services. To protect the Services and other users, we have established rules that you must follow when using our platform. Failure to comply with these rules could lead to the suspension or termination of your account.
            </p>

            <h3 id="respecting-others-rights" className="mb">Respecting Others' Rights</h3>
            <p className="mb-4">
              Vision AR respects the rights of others. And so should you. You therefore may not use the Services, or enable anyone else to use the Services, in a manner that violates or infringes someone else’s rights of publicity, privacy, copyright, trademark, or other intellectual property right. When you submit content to the Services, you agree and represent that you own that content, or that you have received all necessary permissions, clearances, and authorizations in order to submit it to the Services (including, if applicable, the right to make mechanical reproductions of the musical works embodied in any sound recordings, synchronize any compositions to any content, publicly perform any compositions or sound recordings, or any other applicable rights for any music not provided by Vision AR that you include in your content) and grant the rights and licenses contained in these Terms for your content. You also agree that you will not use or attempt to use another user’s account except as permitted by Vision AR or its affiliates.
            </p>
            <p className="mb-2">
              Vision AR honors copyright laws, including the Digital Millennium Copyright Act and takes reasonable steps to expeditiously remove from our Services any infringing material that we become aware of. If Vision AR becomes aware that a user has repeatedly infringed copyrights, we will take reasonable steps within our power to suspend or terminate the user’s account.
            </p>
            <ul className="list-disc pl-5 mb-4" style={{paddingLeft:'1.2rem'}}>
              <li>Contain the physical or electronic signature of a person authorized to act on behalf of the copyright owner;</li>
              <li>Identify the copyrighted work claimed to have been infringed;</li>
              <li>Identify the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed, or access to which is to be disabled, and information reasonably sufficient to let us locate the material;</li>
              <li>Provide your contact information, including your address, telephone number, and an email address;</li>
              <li>Provide a personal statement that you have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
              <li>Provide a statement that the information in the notification is accurate and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
            </ul>
            <p className="mb-4">
              Ensure that you own or have the rights to any content you share on the Services. Using someone else's content without permission could result in the termination of your account. If you notice anything that infringes on your intellectual property rights, please inform us.
            </p>

            <h3 id="safety" className="mb">Safety</h3>
            <p className="mb-4">
              We work hard to make our Services a safe space for everyone, but we can't do it alone. By using the Services, you agree to always follow these Terms, including our Community Guidelines and any other policies we provide to help maintain safety.
            </p>
            <p className="mb-4">
              If you don’t comply, we may remove any offending content, restrict or terminate your account, retain data according to our policies, and notify third parties, including law enforcement, if necessary. These actions help protect our users, investigate and address potential violations, and resolve any fraud or security concerns.
            </p>
            <p className="mb-4">
              We also care about your physical safety while using our Services. Please don't use the Services in a way that could distract you from following traffic or safety laws. For example, never use the Services while driving, and don't put yourself or others at risk to capture a Vision AR or interact with other features.
            </p>
            <p className="mb-4" style={{marginTop:'1rem',fontStyle: 'italic'}}>
              In summary: We strive to keep our Services safe, but we need your cooperation. These Terms, our Community Guidelines, and other policies are here to help protect everyone. Always prioritize safety when using our Services.
            </p>

            <h3 id="your-account" className="mb">Your Account</h3>
            <p className="mb-4">
              To access certain Services, you'll need to create an account. You agree to provide accurate, complete, and up-to-date information for your account. You’re responsible for all activity on your account, so it's crucial to keep it secure. Choosing a strong, unique password and enabling two-factor authentication are good ways to protect your account. If you suspect unauthorized access, contact Support immediately. Any software we provide may automatically download and install updates or new features, which you can manage through your device settings. If we've previously removed or banned you from our Services, you agree not to create a new account without our permission.
            </p>
            <p className="mb-4" style={{marginTop:'1rem',fontStyle: 'italic'}}>
              In summary: Keep your account secure, and only use it if you have our authorization.
            </p>

            <h3 id="data-charges" className="mb">Data Charges and Mobile Phones</h3>
            <p className="mb-4">
              You are responsible for any mobile charges incurred while using our Services, including data, SMS, MMS, or other messaging protocols (collectively, "Messages"). If you're unsure about potential charges, please check with your service provider before using the Services.
            </p>
            <p className="mb-4">
              By providing us with your mobile phone number, you consent to receive Messages from Vision AR related to the Services, including promotions (with your consent or as permitted by law), account updates, and other communications regarding your relationship with Vision AR. These Messages may be sent even if your number is on a state, federal, or international Do Not Call list.
            </p>
            <p className="mb-4">
              If you change or deactivate the mobile number associated with your account, please update your account information in Settings within 72 hours to ensure Messages intended for you don't reach someone else.
            </p>
            <p className="mb-4" style={{marginTop:'1rem',fontStyle: 'italic'}}>
              In summary: We may send you Messages, and you may incur mobile charges when using our Services.
            </p>

            <h3 id="modifying-services" className="mb">Modifying the Services and These Terms</h3>
            <p className="mb-4">
              We’re constantly enhancing our Services and developing new ones, which means features, products, or functionalities may be added or removed, and we might even suspend or discontinue the Services entirely. We can make these changes at any time, for any reason, and we might not provide advance notice.
            </p>
            <p className="mb-4">
              As a result, we may need to update these Terms to reflect changes in our Services, legal requirements, or for other legal and security reasons. If these updates are significant, we’ll give you reasonable notice (unless immediate changes are required, such as for legal compliance or when launching new features). By continuing to use the Services after the changes take effect, you accept the updated Terms.
            </p>
            <p className="mb-4" style={{marginTop:'1rem',fontStyle: 'italic'}}>
              In summary: Our Services will evolve, and we may update these Terms occasionally to reflect those changes or for other reasons.
            </p>

            <h3 id="termination" className="mb">Termination and Suspension</h3>
            <p className="mb-4">
              While we hope you stay with Vision AR for the long term, you can choose to end these Terms at any time if you disagree with any changes we've made or for any other reason by deleting your Vision AR account (or the account associated with the specific Service you're using).
            </p>
            <p className="mb-4">
              We may restrict, suspend, or terminate your access to the Services if you violate these Terms, our Community Guidelines, or the law, or for other reasons, including factors beyond our control. This could include deactivating your account due to prolonged inactivity or reclaiming your username. While we’ll aim to give you reasonable notice, we may not always be able to do so.
            </p>
            <p className="mb-4">
              If we take action to restrict, terminate, or suspend your access due to a violation of our Community Guidelines, we’ll notify you and provide an opportunity to appeal. Our decision will be based on the facts available to us, considering factors like the severity, frequency, and intent behind any violations.
            </p>
            <p className="mb-4">
              Whether you or Vision AR terminates these Terms, certain sections (2, 3 as applicable, and 6-24) will continue to apply.
            </p>
            <p className="mb-4" style={{marginTop:'1rem',fontStyle: 'italic'}}>
              In summary: You can stop using the Services or delete your account at any time, especially if you disagree with changes to these Terms. We may restrict or terminate your access for the reasons outlined above, usually with notice and an option to appeal.
            </p>

            <h3 id="indemnity" className="mb">Indemnity</h3>
            <p className="mb-4">
              You agree, as allowed by law, to indemnify, defend, and hold harmless Vision AR, along with our affiliates, directors, officers, shareholders, employees, licensors, and agents, from any complaints, claims, damages, losses, costs, liabilities, and expenses (including legal fees) that arise from: (a) your use of the Services or any third-party products or services connected to the Services, even if recommended or approved by Vision AR, (b) your content, including any claims of infringement related to it, (c) your violation of these Terms or any applicable laws or regulations, or (d) your negligence or intentional misconduct.
            </p>
            <p className="mb-4" style={{marginTop:'1rem',fontStyle: 'italic'}}>
              In summary: If your actions cause us harm, you agree to cover the costs.
            </p>

            <h3 id="disclaimers" className="mb">Disclaimers</h3>
            <p className="mb-4">
              THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT ANY WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, TO THE EXTENT PERMITTED BY LAW. While we strive to offer a good user experience, we do not guarantee that: (A) the Services will always be secure, error-free, or timely; (B) the Services will operate without delays, disruptions, or imperfections; or (C) the content or information you access through the Services will be accurate or up-to-date.
            </p>
            <p className="mb-4">
              Neither we nor our affiliates take responsibility or assume liability for any content created, uploaded, posted, sent, received, or stored by you, other users, or third parties on or through our Services. You understand and agree that you may encounter content that is offensive, illegal, misleading, or otherwise inappropriate, and we or our affiliates will not be held responsible for it.
            </p>
            <p className="mb-4" style={{marginTop:'1rem',fontStyle: 'italic'}}>
              In summary: We’ll do our best to make the Services available and functional, but we make no guarantees and are not responsible for content that isn't ours.
            </p>

            <h3 id="limitation-liability" className="mb">Limitation of Liability</h3>
            <p className="mb-4">
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE AND OUR MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES, AFFILIATES, LICENSORS, AGENTS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES, (B) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON OR THROUGH THE SERVICES, OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL OUR AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED THE GREATER OF $100 USD OR THE AMOUNT YOU PAID US IN THE 12 MONTHS PRECEDING THE DATE OF THE ACTIVITY GIVING RISE TO THE CLAIM.
            </p>
            <p className="mb-4" style={{marginTop:'1rem',fontStyle: 'italic'}}>
              In summary: We limit our liability for anything you do, instances where you cannot access the Services, things others do, and any issues resulting from unauthorized use of our Services. Where we are liable to you and you have suffered some loss, we limit our liability to a set amount.
            </p>

            <h3 id="choice-of-law" className="mb">Choice of Law</h3>
            <p className="mb-4">
              Except to the extent they are preempted by U.S. federal law, the laws of Florida, other than its conflict-of-laws principles, govern these Terms and any claims and disputes (whether contract, tort, or otherwise) arising out of or relating to these Terms or their subject matter.
            </p>

            <h3 id="severability" className="mb">Severability</h3>
            <p className="mb-4">
              If any provision of these Terms is found unenforceable, then that provision will be removed from these Terms and not affect the validity and enforceability of any remaining provisions.
            </p>

            <h3 id="california-residents" className="mb">California Residents</h3>
            <p className="mb-4">
              If you are a California resident, in accordance with Cal. Civ. Code § 1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210.
            </p>

            <h3 id="final-terms" className="mb">Final Terms</h3>
            <p className="mb-4">
              These Terms, including the additional terms referenced in Section 3, make up the entire agreement between you and Vision AR, and supersede any prior agreements. These Terms do not create or confer any third-party beneficiary rights. If we do not enforce a provision in these Terms, it will not be considered a waiver of our rights to enforce these Terms. We reserve the right to transfer our rights under these Terms and provide the Services using another entity, provided that entity upholds these Terms. You may not transfer any of your rights or obligations under these Terms without our consent. We reserve all rights not expressly granted to you. Where we have provided summary sections in these Terms, these summaries have been included for your convenience only and you should read these Terms in full to understand your legal rights and obligations.
            </p>

            <h3 id="contact" className="mb">Contact Us</h3>
            <p className="mb-4">
              Vision AR welcomes comments, questions, concerns, or suggestions. You can contact us or get support at <a href="mailto:support@thevision.ar" target="_blank" style={{ color: "teal" }}>support@thevision.ar</a>.
            </p>
          </div>
        </div>
      </TermsOfServiceContainer>
  );
};